<template>
  <div class="container">
    <TheHeader class="header" />
    <div class="sidebar">
      <StSidebar>
        <TheSidebar />
      </StSidebar>
    </div>

    <main class="main">
      <div class="content">
        <slot />
      </div>
      <TheFooter />
    </main>
    <div v-if="isShownCoupon" class="coupon-wrapper">
      <Coupon class="coupon" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCouponStore } from '@st/coupon/stores/useCouponStore'
import { useActiveBetsStore } from '@st/coupon/stores/useActiveBetsStore'
import { useRouteMatching } from '#imports'

const route = useRoute()

const { startsWith, exactlyEquals } = useRouteMatching()
const { outcomeCount } = storeToRefs(useCouponStore())
const { betCounter } = storeToRefs(useActiveBetsStore())

const isNotEmptyCoupon = computed(
  () => outcomeCount.value > 0 || betCounter.value > 0,
)
const isShownCoupon = computed(
  () =>
    (exactlyEquals(route.path, '/') && isNotEmptyCoupon.value) ||
    startsWith(route.path, '/sport') ||
    startsWith(route.path, '/cybersport'),
)
</script>

<style scoped>
.container {
  --header-height: 60px;
  --container-width: clamp(1360px, 100%, 1536px);

  display: grid;
  grid-gap: 0 var(--spacing-200);
  grid-template-areas:
    'header header'
    'sidebar main';
  grid-template-columns: min-content 1fr;

  width: var(--container-width);
  min-height: 100vh;
  margin: 0 auto;
  padding: var(--spacing-0) var(--spacing-200);
}

.header {
  position: sticky;
  z-index: 10;
  top: 0;
  grid-area: header;
}

.sidebar {
  position: sticky;
  z-index: 10;
  top: calc(var(--header-height) + 16px);

  grid-area: sidebar;
  align-self: start;

  height: calc(100vh - var(--header-height) - 16px);
}

.main {
  position: relative;

  display: flex;
  grid-area: main;
  flex-direction: column;

  min-width: 0;
  min-height: calc(100vh - var(--header-height));
}

.coupon-wrapper {
  pointer-events: none;

  position: fixed;
  z-index: 100;
  bottom: 0;

  display: flex;
  justify-content: flex-end;

  width: var(--container-width);
  padding-right: var(--spacing-400);
}

.coupon {
  pointer-events: auto;
}

.content {
  flex-grow: 1;
  padding-top: var(--spacing-200);
}
</style>
